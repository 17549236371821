import React, { useState } from 'react';
import { Typography, Paper, Box, Tooltip, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ChatResponseToolbar from './ChatResponseToolbar';
import CopyIcon from '@mui/icons-material/ContentCopy';

function ChatLine({ line, updateChatLine }) {
  const isAssistant = line.role === 'assistant';
  const [tooltip, setTooltip] = useState('Copy');
  const setCopied = () => setTooltip('Copied!');
  const setCopyError = () => setTooltip('Copy Error!');

  const copyText = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard
        .writeText(line.content.trim())
        .then(setCopied, setCopyError);
    } else {
      setCopyError();
    }
  };

  return (
    <Box
      sx={{
        // display: 'flex',
        justifyContent: isAssistant ? 'flex-end' : 'flex-start',
        mb: 2,
      }}
    >
      <Paper
        variant='outlined'
        sx={{
          p: 2,
          // backgroundColor: isBot ? "primary.light" : "secondary.light",
          borderRadius: isAssistant
            ? '20px 20px 5px 20px'
            : '20px 20px 20px 5px',
          justifyContent: isAssistant ? 'flex-end' : 'flex-start',
        }}
      >
        <Grid container>
          <Grid size={12} align={isAssistant ? 'right' : 'left'}>
            <Typography variant='overline'>{line.role}</Typography>
            {isAssistant ? (
              <Tooltip title={tooltip}>
                <IconButton aria-label='close' onClick={copyText}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            ) : undefined}
          </Grid>
          <Grid size={12}>
            <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
              {line.content.trim()}
            </Typography>
          </Grid>
          {isAssistant ? (
            <Grid size={12} align={'right'}>
              <ChatResponseToolbar
                line={line}
                updateChatLine={updateChatLine}
              />
            </Grid>
          ) : undefined}
        </Grid>
      </Paper>
    </Box>
  );
}

export default ChatLine;
