import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function ManifestNameField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
  label,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (/[#^$<>+%!`&*'"|{}?/\\:=@]/.test(newValue)) {
      setError('Manifest name cannot contain #^$<>+%!`&*\'"|{}?/\\:=@');
    } else if (newValue === '') {
      setError('Manifest name cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label={label ? label : 'Manifest Name'}
          id='name'
          aria-describedby='name-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
          slotProps={{ inputLabel: { shrink: value?.length > 0 } }}
        />
        {error ? (
          <FormHelperText id='name-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='name-helper-text'>
            Enter a name for the manifest
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
