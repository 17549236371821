import React, { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Toolbar,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { deleteChat } from '@/actions/chat';
import { useUser } from '@/providers/UserContext';
import Chat from './Chat';
import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { getChat } from '@/actions/chat';
import ChatDeleteDialog from '@/components/shared/ChatDeleteDialog';

function ExternalAudit() {
  const user = useUser();
  const { audit_id } = useParams();
  const navigate = useNavigate();
  const configuration = useConfiguration();
  const [chat, setChat] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (user?.entity_id && !chat) {
      getChat(configuration.api, audit_id)
        .then((data) => {
          setChat(data);
        })
        .catch((error) => {
          console.log(error);
          navigate('/quartermaster/externalAudit');
        });
    }
  }, [user?.entity_id, audit_id, chat, setChat, configuration.api, navigate]);

  const handleDeleteChat = async (chat_id) => {
    await deleteChat(configuration.api, chat_id);
    navigate('/quartermaster/externalAudit');
  };

  if (!chat) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          {chat.name}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() =>
              navigate({
                pathname: '/quartermaster/externalAudit/edit',
                search: `?audit_id=${audit_id}`,
              })
            }
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />

      <Grid container spacing={2} style={{ padding: '15px' }}>
        <Grid size={12}>
          <Alert severity='success'>
            <AlertTitle>
              Interrogating document <b>{chat?.doc_id}</b>
            </AlertTitle>
            Ask questions below
          </Alert>
        </Grid>
        <Chat key={chat?.updated_at} chat={chat} doc_id={chat?.doc_id} />
      </Grid>
      <ChatDeleteDialog
        key={`delete_${chat?.updated_at}`}
        chat={chat}
        open={deleteOpen}
        deleteChat={handleDeleteChat}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default ExternalAudit;
