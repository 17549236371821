import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Divider, Fab, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SendIcon from '@mui/icons-material/Send';

import PromptField from './PromptField';
import { useConfiguration } from '@/providers/ConfigurationContext';
import ChatBox from '@/components/shared/ChatBox';
import SourceDocument from './SourceDocument';
import { addChatItem, getChatItems, updateChatItem } from '@/actions/chat';
import LoadingMask from '@/components/shared/LoadingMask';
// import GenerationParameters from '@/components/shared/GenerationParameters';
import { searchDocs } from '@/actions/search';
import { generateCompletion } from '@/actions/chat';
import { countTokens } from '@/actions/tokenize';
import buildMessage from './buildMessage';
import IncludeHistoryField from '@/components/shared/IncludeHistoryField';
import buildMessages from './buildMessages';
import { MODEL_TYPES } from '@/models/ModelTypes';

function Chat({ chat, doc_id }) {
  const configuration = useConfiguration();
  const [prompt, setPrompt] = useState('');
  const [prompt_error, setPromptError] = useState(false);
  const [chat_history, setChatHistory] = useState(null);
  const [source_docs, setSourceDocs] = useState([]);
  const [reference_docs, setReferenceDocs] = useState([]);
  const [generation, setGeneration] = useState(null);
  const [include_history, setIncludeHistory] = useState(true);

  useEffect(() => {
    if (chat?.entity_id && !chat_history) {
      getChatItems(configuration.api, chat.entity_id)
        .then((data) => {
          setChatHistory(data);
        })
        .catch((error) => console.log(error));
    }
  }, [chat?.entity_id, chat_history, configuration.api]);

  const addToChatHistory = useCallback(
    async (chat_item) => {
      const content_tokens = await countTokens(
        configuration.api,
        chat_item.content
      );
      addChatItem(configuration.api, chat?.entity_id, {
        ...chat_item,
        content_tokens,
      }).then((resp) => {
        if (chat_item.role === 'assistant') {
          setGeneration(null);
        }
        setChatHistory((prevHistory) => {
          return [...prevHistory, resp];
        });
      });
    },
    [chat?.entity_id, setChatHistory, configuration.api]
  );

  const generateResponse = useCallback(
    async (prompt, chat_history) => {
      setGeneration('');

      const doc_query = include_history
        ? prompt +
          '\n' +
          [...(chat_history || [])]
            .reverse()
            .map((chat_line) => chat_line.content)
            .join('\n')
        : prompt;

      const docs = await searchDocs(
        configuration.api,
        doc_query,
        5,
        [],
        doc_id
      );

      let messages;
      if (include_history) {
        messages = await buildMessages(
          configuration.api,
          configuration.model.llm,
          prompt,
          docs,
          chat_history
        );
      } else {
        const message = await buildMessage(
          configuration.api,
          configuration.model.llm,
          prompt,
          docs
        );
        messages = [message];
      }

      const input_tokens = messages.reduce(
        (accumulator, message) => accumulator + message.content_tokens,
        0
      );

      const response = await generateCompletion(
        configuration.api,
        chat?.entity_id,
        MODEL_TYPES.TEXT,
        messages,
        configuration?.generation?.max_new_tokens,
        configuration?.generation?.temperature,
        configuration?.generation?.top_p,
        configuration?.generation?.typical_p
      );

      const reader = response.body.getReader();
      let line = '',
        finish_reason,
        answer = '';

      try {
        while (true) {
          const { done, value } = await reader.read();
          line += new TextDecoder().decode(value);
          if (line.endsWith('\n')) {
            const lines = line
              .trim()
              .split('\n')
              .filter((line) => line);
            for (let i = 0; i < lines.length; i++) {
              const data = lines[i].substring(5).trim();
              if (data === '[DONE]') {
                continue;
              } else {
                const result = JSON.parse(data);
                if (result?.choices) {
                  if (result?.choices[0]?.finish_reason) {
                    finish_reason = result?.choices[0]?.finish_reason;
                  }
                  if (
                    !configuration.model.llm.eos_token.includes(
                      result?.choices[0]?.delta?.content
                    )
                  ) {
                    answer += result?.choices[0]?.delta?.content;

                    setGeneration((prevGeneration) => {
                      return (
                        prevGeneration + result?.choices[0]?.delta?.content
                      );
                    });
                  }
                } else {
                  if (!result.generated_text) {
                    answer += result.token.text;
                    setGeneration((prevGeneration) => {
                      return prevGeneration + result.token.text;
                    });
                  }
                }
              }
            }
            line = '';
          }
          if (done) {
            setSourceDocs(docs);
            return {
              generated_text: answer,
              finish_reason,
              input_tokens,
              sources: docs,
            };
          }
        }
      } catch (error) {
        setSourceDocs(docs);
        throw error;
      }
    },
    [
      chat?.entity_id,
      configuration?.generation?.max_new_tokens,
      // configuration?.generation?.num_docs,
      configuration?.generation?.temperature,
      configuration?.generation?.top_p,
      configuration?.generation?.typical_p,
      configuration?.model,
      doc_id,
      include_history,
      configuration.api,
    ]
  );

  const handlePromptSubmit = useCallback(() => {
    addToChatHistory({
      name: 'User',
      role: 'user',
      content: prompt,
      order: chat_history.length,
    })
      .then(() => {
        setSourceDocs([]);
        setReferenceDocs([]);
        generateResponse(prompt, chat_history)
          .then(async (response) => {
            const generated_tokens = await countTokens(
              configuration.api,
              response.generated_text.trim()
            );
            try {
              await addToChatHistory({
                name: 'AccQsure',
                role: 'assistant',
                content: response.generated_text.trim(),
                order: chat_history.length + 1,
                sources: JSON.stringify(response.sources),
                finish_reason: response.finish_reason,
                input_tokens: response.input_tokens,
                generated_tokens,
                temperature: configuration?.generation?.temperature,
                include_history: response.include_history,
              });
            } catch (error) {
              console.log(error);
              setGeneration(null);
            }
          })
          .catch((error) => {
            console.log(error);
            setGeneration(null);
          });
        setPrompt('');
        setPromptError(false);
      })
      .catch((error) => {
        console.log(error);
        setGeneration(null);
      });
  }, [
    addToChatHistory,
    chat_history,
    configuration?.generation?.temperature,
    generateResponse,
    prompt,
    configuration.api,
  ]);

  const handleUpdateChatLine = useCallback(
    async (chat_item_id, values) => {
      const resp = await updateChatItem(
        configuration.api,
        chat?.entity_id,
        chat_item_id,
        values
      );
      setChatHistory((prevHistory) => {
        const index = prevHistory.findIndex(
          (line) => line.entity_id === resp.entity_id
        );
        const new_chat_history = [...prevHistory];
        new_chat_history[index] = resp;
        return new_chat_history;
      });
    },
    [chat?.entity_id, configuration.api]
  );

  if (!chat_history) {
    return <LoadingMask />;
  }

  return (
    <>
      <Grid size={12}>
        <ChatBox
          chat_history={chat_history || []}
          generation={generation}
          updateChatLine={handleUpdateChatLine}
        />
      </Grid>
      <Grid size={12}>
        <Typography variant='caption' sx={{ margin: '10px' }}>
          <b>Disclaimer:</b> The generated output is for assistance only and
          should be reviewed by subject matter experts before being used in any
          product or decision.
        </Typography>
      </Grid>
      {source_docs.length ? (
        <Box
          sx={{
            margin: '10px',
          }}
        >
          <Grid size={12}>
            <Divider />
          </Grid>{' '}
          <Grid size={12}>
            <Typography variant='h6'>Sources</Typography>{' '}
          </Grid>
          <Grid container spacing={0} size={12}>
            {reference_docs.map((doc, i) => {
              return (
                <Grid size={12}>
                  <SourceDocument key={`reference_doc_${i}`} doc={doc} />
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={0} size={12}>
            {source_docs.map((doc, i) => {
              return (
                <Grid size={12}>
                  <SourceDocument key={`source_doc_${i}`} doc={doc} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : undefined}
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid container size={12} paddingBottom={'15px'}>
        <Grid size={11}>
          <PromptField
            value={prompt}
            error={prompt_error}
            setValue={setPrompt}
            setError={setPromptError}
            onSubmit={handlePromptSubmit}
            disabled={generation !== null}
          />
        </Grid>
        <Grid size={1} align='right'>
          <Fab
            color='primary'
            aria-label='send'
            onClick={handlePromptSubmit}
            size='small'
            disabled={generation !== null}
          >
            <SendIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid size={12} align='right'>
        <IncludeHistoryField
          value={include_history}
          setValue={setIncludeHistory}
        />
      </Grid>
      {/* <Grid size={12}>
        <GenerationParameters />
      </Grid> */}
    </>
  );
}

export default Chat;
