import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Divider, Fab, Button, Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SearchIcon from '@mui/icons-material/Search';

import DocumentTypesField from './DocumentTypesField';
import { searchDocs } from '@/actions/search';
import SearchField from './SearchField';
import SearchResults from './SearchResults';
import { useConfiguration } from '@/providers/ConfigurationContext';
import { useDocTypes } from '@/providers/DocTypesContext';
import LoadingMask from '@/components/shared/LoadingMask';

function SearchDocuments({ selectDocument }) {
  const configuration = useConfiguration();
  const doc_types = useDocTypes();
  const [search, setSearch] = useState('');
  const [num_docs] = useState(20);
  const [num_docs_error] = useState(false);
  const [search_error, setSearchError] = useState(false);
  const [search_results, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documents_filter, setDocumentsFilter] = useState(null);
  const [selected_document, setSelectedDocument] = useState(null);
  const [selection_doc_types, setSelectionDocTypes] = useState(null);

  useEffect(() => {
    if (doc_types && !selection_doc_types) {
      const uniqueLevels = [
        ...new Set(doc_types.map((doc_type) => doc_type.level)),
      ];
      const topTwoLevels = uniqueLevels.sort((a, b) => b - a).slice(0, 2);

      // Step 2: Filter the array to keep only objects with those top two levels
      const selectable = doc_types.filter((doc_type) =>
        topTwoLevels.includes(doc_type.level)
      );
      setDocumentsFilter(selectable[0]);
      setSelectionDocTypes(selectable);
    }
  }, [
    doc_types,
    selection_doc_types,
    setSelectionDocTypes,
    setDocumentsFilter,
  ]);

  const handleSearchSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const docs = await searchDocs(
        configuration.api,
        search,
        parseInt(num_docs),
        [documents_filter?.code]
      );

      const unique_docs = [];
      const doc_ids = new Set();
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        if (!doc_ids.has(doc.doc_id)) {
          unique_docs.push(doc);
          doc_ids.add(doc.doc_id);
        }
      }
      setSearchResults(unique_docs);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [
    search,
    num_docs,
    documents_filter?.code,
    setSearchResults,
    configuration.api,
  ]);

  const handleDocSelection = useCallback(
    async (id) => {
      const chunk = search_results.find((chunk) => chunk.id === id);
      setSelectedDocument(chunk);
    },
    [search_results, setSelectedDocument]
  );

  const saveDocId = useCallback(async () => {
    selectDocument(selected_document.doc_id, selected_document.title);
    window.scrollTo(0, 0);
  }, [selected_document, selectDocument]);

  if (!selection_doc_types) {
    return <LoadingMask />;
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h5'>Document Selection</Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} align='left'>
        <DocumentTypesField
          options={selection_doc_types || []}
          value={documents_filter}
          setValue={setDocumentsFilter}
        />
      </Grid>
      <Grid container size={12} paddingBottom={'15px'} spacing={1}>
        <Grid size={11}>
          <SearchField
            value={search}
            setValue={setSearch}
            error={search_error || num_docs_error}
            setError={setSearchError}
            onSubmit={handleSearchSubmit}
          />
        </Grid>
        <Grid size={1} align='right'>
          <Fab
            color='primary'
            aria-label='send'
            onClick={handleSearchSubmit}
            size='small'
            disabled={!documents_filter || loading || num_docs_error}
          >
            <SearchIcon />
          </Fab>
        </Grid>
      </Grid>
      {search_results.length ? (
        <>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Typography variant='h6'>Results</Typography>
          </Grid>
          <Grid size={12}>
            <SearchResults
              docs={search_results}
              setSelectedDocument={handleDocSelection}
            />
          </Grid>
          {selected_document ? (
            <>
              <Grid size={12}>
                <Divider />
              </Grid>
              <Grid size={12}>
                <Typography variant='h6'>
                  <b>{selected_document?.title}</b> selected for audit
                </Typography>
              </Grid>
              <Grid size={12}>
                <Button onClick={saveDocId} variant='contained'>
                  Confirm
                </Button>
              </Grid>
            </>
          ) : (
            <Grid size={12}>
              <Alert severity='info'>Select a document to audit</Alert>
            </Grid>
          )}
        </>
      ) : (
        <Grid size={12}>
          <Alert severity='info'>Search for a document to audit</Alert>
        </Grid>
      )}
    </Grid>
  );
}

export default SearchDocuments;
