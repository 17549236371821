import React, { useEffect, useState, useCallback } from 'react';
import {
  Stack,
  Typography,
  Toolbar,
  Divider,
  Button,
  IconButton,
  Paper,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import {
  getInspection,
  updateInspection,
  createInspection,
} from '@/actions/inspection';
import { getManifest } from '@/actions/manifest';
import InspectionNameField from './InspectionNameField';
import InspectionDocumentTypeField from './InspectionDocumentTypeField';
import InspectionManifestsField from './InspectionManifestsField';
import InspectionDocumentsField from './InspectionDocumentsField';
import DocumentUploader from '../../../shared/DocumentUploader';
import { countTokens } from '@/actions/tokenize';
import ErrorSnackBar from '@/components/shared/ErrorSnackBar';
import { insertInspectionAsset } from '../../../../actions/inspectionAsset';
import INSPECTION_STATUSES from '../../../inspectionStatuses';

function InspectionForm({ type }) {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const configuration = useConfiguration();
  const [inspection, setInspection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState('');
  const [name_error, setNameError] = useState(false);
  const [document_type, setDocumentType] = useState(undefined);
  const [manifests, setManifests] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [doc_content, setDocContent] = useState(undefined);
  const [doc_title, setDocTitle] = useState(undefined);
  const [document_error, setDocumentError] = useState(undefined);
  const [global_manifest, setGlobalManifest] = useState(null);

  const sp = new URLSearchParams(location.search);
  const inspection_id = sp.get('inspection_id');

  const base_path =
    type === INSPECTION_TYPES.EFFECTIVE
      ? '/quartermaster/internalAudit'
      : '/quartermaster/evaluator';

  useEffect(() => {
    if (user?.entity_id && !inspection) {
      if (inspection_id) {
        getInspection(configuration.api, inspection_id)
          .then((data) => {
            setInspection(data);
            setName(data?.name);
            setDocumentType({ entity_id: data?.document_type_id });
            setManifests(
              data?.manifests
                .filter(
                  (manifest) => manifest.reference_document_content_id // global manifest should be only one with no reference doc
                )
                .map((manifest) => {
                  return {
                    entity_id: manifest.manifest_entity_id,
                    name: manifest.manifest_name,
                  };
                }) || []
            );
            setDocuments(
              data?.documents.map((document) => {
                return {
                  entity_id: document.document_entity_id,
                  name: document.document_name,
                };
              }) || []
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [
    user?.entity_id,
    inspection_id,
    inspection,
    setInspection,
    setName,
    setDocumentType,
    setManifests,
    setDocuments,
    configuration.api,
  ]);
  useEffect(() => {
    if (type === INSPECTION_TYPES.PRELIMINARY && !global_manifest) {
      getManifest(configuration.api, 'global')
        .then((data) => {
          setGlobalManifest(data);
        })
        .catch((error) => console.log(error));
    }
  }, [type, global_manifest, configuration.api, setGlobalManifest]);

  const handleDocumentUpload = useCallback(
    async (files) => {
      if (files[0]) {
        const contents = files[0].processed_contents || files[0].contents;
        const tokens = await countTokens(configuration.api, contents);
        if (tokens > configuration.model.llm.max_input_length - 500) {
          setDocumentError('File is too large.  Upload a smaller document');
        } else {
          setDocContent(contents);
          setDocTitle(files[0].name);
          if (!Boolean(name)) {
            setName(files[0].name);
          }
        }
      }
    },
    [
      configuration.api,
      configuration.model,
      setDocContent,
      setDocTitle,
      setDocumentError,
      name,
      setName,
    ]
  );

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      name,
      type,
      document_type_id: document_type.entity_id,
      manifests: manifests.map((manifest) => manifest.entity_id),
      documents: documents.map((document) => document.entity_id),
    };
    if (type === INSPECTION_TYPES.PRELIMINARY) {
      payload.manifests.unshift(global_manifest?.entity_id);
    }

    let updated_inspection;
    if (inspection_id) {
      updated_inspection = await updateInspection(
        configuration.api,
        inspection.entity_id,
        inspection.status === INSPECTION_STATUSES.DRAFT ? payload : { name }
      );
    } else {
      updated_inspection = await createInspection(configuration.api, payload);
      if (doc_content) {
        const asset = await insertInspectionAsset(
          configuration.api,
          updated_inspection.entity_id,
          doc_title,
          'text/markdown',
          doc_content
        );
        updated_inspection = await updateInspection(
          configuration.api,
          updated_inspection.entity_id,
          { doc_content_id: asset.asset_id }
        );
      }
    }
    navigate(`${base_path}/${updated_inspection.entity_id}`);
  };

  if (!user?.entity_id || (!inspection && inspection_id)) {
    return <LoadingMask />;
  }

  return (
    <Grid container>
      <Grid size={12}>
        <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
          <Toolbar
            sx={{
              minHeight: '55px !important',
            }}
          >
            <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Typography variant='h6' sx={{ mr: 4 }}>
              {inspection ? 'Edit Inspection' : 'New Inspection'}
            </Typography>
          </Toolbar>
        </Paper>
      </Grid>
      <Divider />
      <Grid size={12}>
        <Box
          padding='15px'
          sx={{
            maxWidth: 600,
          }}
        >
          <Grid container spacing={2} style={{ padding: '15px' }}>
            {type === INSPECTION_TYPES.EFFECTIVE ? (
              <>
                <Grid size={12}>
                  <InspectionNameField
                    value={name}
                    setValue={setName}
                    error={name_error}
                    setError={setNameError}
                    setChanged={() => setPristine(false)}
                  />
                </Grid>
                <Grid size={12}>
                  <InspectionDocumentTypeField
                    inspection_type={type}
                    value={document_type}
                    setValue={setDocumentType}
                    setChanged={() => setPristine(false)}
                    edit={Boolean(inspection)}
                  />
                </Grid>
                {document_type ? (
                  <>
                    <Grid size={12}>
                      <InspectionManifestsField
                        value={manifests}
                        setValue={setManifests}
                        document_type_id={document_type.entity_id}
                        setChanged={() => setPristine(false)}
                        edit={Boolean(inspection)}
                      />
                    </Grid>
                    <Grid size={12}>
                      <InspectionDocumentsField
                        value={documents}
                        setValue={setDocuments}
                        document_type_id={document_type.entity_id}
                        setChanged={() => setPristine(false)}
                        edit={Boolean(inspection)}
                      />
                    </Grid>
                  </>
                ) : undefined}
              </>
            ) : (
              <>
                <Grid size={12}>
                  <DocumentUploader
                    setDocuments={handleDocumentUpload}
                    disabled={Boolean(inspection)}
                  />
                </Grid>
                {doc_content || inspection?.doc_content_id ? (
                  <>
                    {!Boolean(inspection) ? (
                      <Grid size={12}>
                        <Typography
                          variant='subtitle'
                          sx={{ wordBreak: 'break-all' }}
                        >
                          Uploaded <b>{doc_title}</b>
                        </Typography>
                      </Grid>
                    ) : undefined}
                    <Grid size={12}>
                      <Divider />
                    </Grid>
                    <Grid size={12}>
                      <InspectionNameField
                        value={name}
                        setValue={setName}
                        error={name_error}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid size={12}>
                      <InspectionDocumentTypeField
                        inspection_type={type}
                        value={document_type}
                        setValue={setDocumentType}
                        setChanged={() => setPristine(false)}
                        edit={Boolean(inspection)}
                      />
                    </Grid>
                  </>
                ) : undefined}
                {document_type ? (
                  <Grid size={12}>
                    <InspectionManifestsField
                      value={manifests}
                      setValue={setManifests}
                      document_type_id={document_type.entity_id}
                      setChanged={() => setPristine(false)}
                      edit={Boolean(inspection)}
                    />
                  </Grid>
                ) : undefined}
              </>
            )}
          </Grid>
        </Box>

        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <Toolbar>
            <Stack spacing={2} direction='row'>
              <LoadingButton
                variant='contained'
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  pristine ||
                  Boolean(name_error) ||
                  !Boolean(name) ||
                  !Boolean(document_type) ||
                  !manifests?.length
                }
              >
                Submit
              </LoadingButton>

              <Button
                variant='outlined'
                color='inherit'
                onClick={() => navigate(-1)}
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
      </Grid>
      <ErrorSnackBar
        message={document_error}
        onClear={() => {
          setDocContent('');
          setDocumentError(undefined);
        }}
      />
    </Grid>
  );
}

export default InspectionForm;
