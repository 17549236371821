import APP_ROLES from '../components/AppRoles';

const calculateUserRoles = (user) => {
  if (user?.profile?.['urn:zitadel:iam:org:project:roles']) {
    const groups = Object.keys(
      user?.profile?.['urn:zitadel:iam:org:project:roles']
    );
    let roles = [];
    groups.forEach((group) => {
      if (group === 'user') {
        roles.push(APP_ROLES.USER);
      }
      if (group === 'admin') {
        roles.push(APP_ROLES.ADMIN);
      }
      if (group === 'accqsureadmin') {
        roles.push(APP_ROLES.ADMIN_ACCQSURE);
      }
    });
    return roles;
  }
  return [];
};
export default calculateUserRoles;
