import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Link,
  Typography,
  Toolbar,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useUser } from '@/providers/UserContext';

import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { getInspection, deleteInspection } from '@/actions/inspection';
import { getManifest } from '@/actions/manifest';
import { getManifestChecks } from '@/actions/manifestCheck';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import InspectionDeleteDialog from '../../InspectionDeleteDialog';
import toNiceTimestamp from '@/util/toNiceTimestamp';
import INSPECTION_STATUSES from '../../../inspectionStatuses';
import { LoadingButton } from '@mui/lab';
import { runInspection } from '../../../../actions/inspection';
import InspectionDocuments from './InspectionDocuments';
import { getInspectionAsset } from '../../../../actions/inspectionAsset';
import DocumentViewDialog from '../../../shared/DocumentViewDialog';

function Inspection() {
  const user = useUser();
  const { inspection_id } = useParams();
  const navigate = useNavigate();

  const configuration = useConfiguration();
  const [inspection, setInspection] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [manifests, setManifests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [doc_content, setDocContent] = useState(null);
  const [doc_title, setDocTitle] = useState(null);

  useEffect(() => {
    if (user?.entity_id && !inspection) {
      getInspection(configuration.api, inspection_id)
        .then((data) => {
          setInspection(data);
          setDocContent(null);
        })
        .catch((error) => {
          console.log(error);
          navigate('/quartermaster');
        });
    }
  }, [
    user?.entity_id,
    inspection_id,
    inspection,
    setInspection,
    setDocContent,
    configuration.api,
    navigate,
  ]);

  useEffect(() => {
    if (inspection?.doc_content_id && doc_content === null) {
      getInspectionAsset(
        configuration.api,
        inspection?.entity_id,
        inspection?.doc_content_id
      )
        .then((data) => {
          setDocContent(data.contents);
          setDocTitle(data.file_name);
        })
        .catch((error) => {
          console.log(error);
          setDocContent('');
        });
    }
  }, [
    inspection,
    doc_content,
    setDocContent,
    setDocTitle,
    configuration.api,
    navigate,
  ]);

  useEffect(() => {
    if (!manifests && inspection) {
      if (inspection?.status === INSPECTION_STATUSES.DRAFT) {
        const task = Promise.all(
          (inspection?.manifests || []).map(async (inspection_manifest) => {
            const [manifest, checks] = await Promise.all([
              getManifest(configuration.api, inspection_manifest.manifest_id),
              getManifestChecks(
                configuration.api,
                inspection_manifest.manifest_id
              ),
            ]);
            return {
              ...manifest,
              checks: checks.sort((a, b) => {
                if (a.section === b.section) {
                  return (a.name > b.name) - (a.name < b.name);
                } else {
                  if (a.section === 'global') {
                    return -1;
                  } else if (b.section === 'global') {
                    return 1;
                  } else {
                    return (a.section > b.section) - (a.section < b.section);
                  }
                }
              }),
            };
          })
        );
        task
          .then((data) => {
            setManifests(
              data.sort((a, b) => {
                if (a.global) {
                  return -1;
                } else if (b.global) {
                  return 1;
                } else {
                  return (a.name > b.name) - (a.name < b.name);
                }
              })
            );
          })
          .catch((error) => {
            console.log(error);
            navigate('/quartermaster');
          });
      } else {
        setManifests(
          (inspection?.manifests || [])
            .map((manifest) => {
              return {
                name: manifest.manifest_name,
                entity_id: manifest.manifest_id,
              };
            })
            .sort((a, b) => {
              if (a.global) {
                return -1;
              } else if (b.global) {
                return 1;
              } else {
                return (a.name > b.name) - (a.name < b.name);
              }
            })
        );
      }
    }
  }, [inspection, manifests, setManifests, configuration.api, navigate]);

  if (
    !inspection ||
    (inspection?.status === INSPECTION_STATUSES.DRAFT && !manifests)
  ) {
    return <LoadingMask />;
  }

  const base_path =
    inspection?.type === INSPECTION_TYPES.EFFECTIVE
      ? '/quartermaster/internalAudit'
      : '/quartermaster/evaluator';

  const handleDeleteInspection = async () => {
    await deleteInspection(configuration.api, inspection_id);
    navigate(base_path);
  };

  const handleRunInspection = async () => {
    setLoading(true);
    const updated_inspection = await runInspection(
      configuration.api,
      inspection_id
    );
    setInspection(updated_inspection);
    setLoading(false);
  };

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(base_path)}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          {inspection.name}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() =>
              navigate({
                pathname: `${base_path}/edit`,
                search: `?inspection_id=${inspection_id}`,
              })
            }
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          {inspection?.status === INSPECTION_STATUSES.DRAFT ? (
            <Button
              onClick={() => setDeleteOpen(true)}
              startIcon={<DeleteOutlinedIcon />}
            >
              Delete
            </Button>
          ) : undefined}
        </Stack>
      </Toolbar>
      <Divider />

      <Box padding='15px'>
        <Typography variant='subtitle'>Basic Information</Typography>
        <Divider />
        <br />
        <Grid
          container
          component='dl' // mount a Definition List
          spacing={0}
          sx={{ display: 'table' }}
        >
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>ID:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {inspection?.entity_id}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Status:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {inspection?.status}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Created:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {toNiceTimestamp(inspection?.created_at, true)}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Last Updated:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {toNiceTimestamp(inspection?.updated_at, true)}
            </Typography>
          </Grid>
          {inspection?.status === INSPECTION_STATUSES.COMPLETE ? (
            <>
              <Grid>
                <Typography
                  component='dt'
                  variant='body1'
                  sx={{ display: 'table-cell', width: '250px' }}
                >
                  <b>Critical Checks:</b>
                </Typography>
                <Typography
                  component='dd'
                  variant='body2'
                  sx={{ display: 'table-cell' }}
                >
                  {inspection?.critical_compliant} /{' '}
                  {inspection?.critical_compliant +
                    inspection?.critical_non_compliant}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  component='dt'
                  variant='body1'
                  sx={{ display: 'table-cell', width: '250px' }}
                >
                  <b>Non-Critical Checks:</b>
                </Typography>
                <Typography
                  component='dd'
                  variant='body2'
                  sx={{ display: 'table-cell' }}
                >
                  {inspection?.non_critical_compliant} /{' '}
                  {inspection?.non_critical_compliant +
                    inspection?.non_critical_non_compliant}
                </Typography>
              </Grid>
            </>
          ) : undefined}
          {Boolean(doc_content) ? (
            <>
              <Grid>
                <br />
                <Divider />
                <br />
              </Grid>
              <Grid>
                <Link
                  component='button'
                  variant='subtitle'
                  onClick={() => {
                    setDocOpen(true);
                  }}
                >
                  <Typography variant='h6'>View Document</Typography>
                </Link>
                <DocumentViewDialog
                  title={doc_title}
                  contents={doc_content}
                  open={docOpen}
                  handleClose={() => setDocOpen(false)}
                />
              </Grid>
            </>
          ) : undefined}
        </Grid>
      </Box>
      {inspection?.status === INSPECTION_STATUSES.DRAFT ? (
        <>
          <Divider />
          <Box padding='15px'>
            <LoadingButton
              loading={loading}
              variant='contained'
              color='primary'
              onClick={handleRunInspection}
            >
              Run
            </LoadingButton>
          </Box>
        </>
      ) : undefined}
      <Divider />
      <InspectionDocuments
        inspection={inspection}
        manifests={manifests}
        setInspection={setInspection}
      />
      {inspection.status === INSPECTION_STATUSES.DRAFT ? (
        <InspectionDeleteDialog
          key={`delete_${inspection?.updated_at}`}
          inspection={inspection}
          open={deleteOpen}
          deleteInspection={handleDeleteInspection}
          handleClose={() => setDeleteOpen(false)}
        />
      ) : undefined}
    </>
  );
}

export default Inspection;
