import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

import {
  createChat,
  deleteChat,
  getChats,
  updateChat,
} from '../../actions/chat';
import { useUser } from '../../providers/UserContext';
import Chat from './Chat';
import LoadingMask from '../shared/LoadingMask';
import ChatSelection from '../shared/ChatSelection';
import CHAT_TYPES from '../chatTypes';
import { useConfiguration } from '../../providers/ConfigurationContext';
import { usePageTitleDispatch } from '../../providers/PageTitleContext';
import { SET_PAGE_TITLE } from '../../providers/actionTypes';

const page_title = 'Voyager';

function Voyager() {
  const configuration = useConfiguration();
  const user = useUser();
  const [chats, setChats] = useState(null);
  const [activeChat, setActiveChat] = useState(null);

  const pageTitleDispatch = usePageTitleDispatch();

  useEffect(() => {
    pageTitleDispatch({ type: SET_PAGE_TITLE, payload: page_title });
  }, [pageTitleDispatch]);

  useEffect(() => {
    if (user?.entity_id && !chats) {
      getChats(configuration.api, CHAT_TYPES.VOYAGER)
        .then((data) => {
          data.sort(
            (a, b) =>
              new Date(b.updated_at).valueOf() -
              new Date(a.updated_at).valueOf()
          );
          setChats(data);
          setActiveChat(data[0]);
        })
        .catch((error) => console.log(error));
    }
  }, [user?.entity_id, chats, configuration.api]);

  useEffect(() => {
    if (user?.entity_id && chats && chats.length === 0) {
      createChat(configuration.api, {
        name: 'New Chat',
        type: CHAT_TYPES.VOYAGER,
      })
        .then((chat) => {
          setChats([chat]);
          setActiveChat(chat);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user?.entity_id, chats, configuration.api]);

  const handleUpdateChat = async (chat_id, update) => {
    const updated_chat = await updateChat(configuration.api, chat_id, update);
    setChats((prevChats) => {
      const index = prevChats.findIndex((chat) => chat.entity_id === chat_id);
      const new_chats = [...prevChats];
      new_chats[index] = updated_chat;
      return new_chats;
    });
    setActiveChat(updated_chat);
  };

  const handleDeleteChat = async (chat_id) => {
    await deleteChat(configuration.api, chat_id);
    const new_chats = [...chats].filter((chat) => chat.entity_id !== chat_id);
    if (new_chats.length) {
      setChats(new_chats);
      setActiveChat(new_chats[0]);
    } else {
      const new_chat = await createChat(configuration.api, {
        name: 'New Chat',
        type: CHAT_TYPES.VOYAGER,
      });
      setChats([new_chat]);
      setActiveChat(new_chat);
    }
  };

  if (!chats) {
    return <LoadingMask />;
  }

  return (
    <Grid container>
      <Grid size={2}>
        <Box sx={{ padding: '15px' }}>
          <ChatSelection
            type={CHAT_TYPES.VOYAGER}
            new_name={'New Chat'}
            chats={chats}
            setActiveChat={setActiveChat}
            setChats={setChats}
          />
        </Box>
      </Grid>
      <Grid size={10}>
        <Chat
          key={activeChat?.updated_at}
          chat={activeChat || {}}
          updateChat={handleUpdateChat}
          deleteChat={handleDeleteChat}
        />
      </Grid>
    </Grid>
  );
}

export default Voyager;
