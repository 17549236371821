import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { Typography, Stack, Divider, Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfiguration } from '../../../providers/ConfigurationContext';
import ChatBox from '../../shared/ChatBox';
import SourceDocument from './SourceDocument';
import {
  addChatItem,
  getChatItems,
  updateChatItem,
} from '../../../actions/chat';
import LoadingMask from '../../shared/LoadingMask';
// import GenerationParameters from '../../shared/GenerationParameters';
import ChatEditDialog from '../../shared/ChatEditDialog';
import ChatDeleteDialog from '../../shared/ChatDeleteDialog';
import DocumentTypesField from '../../shared/DocumentTypesField';
import { searchDocs } from '../../../actions/search';
import { generateCompletion } from '../../../actions/chat';
import Suggestions from './Suggestions';
import { countTokens } from '../../../actions/tokenize';
import IncludeHistoryField from '../../shared/IncludeHistoryField';
import buildMessages from '@/components/shared/buildMessages';
import { MODEL_TYPES } from '../../../models/ModelTypes';
import MultiModalPrompt from './MultiModalPrompt';
import { useDocTypes } from '../../../providers/DocTypesContext';

function Chat({ chat, updateChat, deleteChat }) {
  const configuration = useConfiguration();
  const doc_types = useDocTypes();
  const [prompt, setPrompt] = useState('');
  const [prompt_error, setPromptError] = useState(false);
  const [chat_history, setChatHistory] = useState(null);
  const [source_docs, setSourceDocs] = useState([]);
  const [generation, setGeneration] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [include_history, setIncludeHistory] = useState(true);
  const [documents_filter, setDocumentsFilter] = useState(null);
  const [image_id, setImageId] = useState(null);
  const [image_data, setImageData] = useState(null);
  const [suggestions] = useState(
    _.sampleSize(
      [
        'What are the post market considerations for security policy?',
        'Can the security risk files be part of the safety risk file?',
        'What are the different signals that can impact cybersecurity risk assessment?',
        'Create a Cybersecurity Incident Response Plan.',
        'What are the ways to do risk assessment for security risks?',
        'What is CVSS?',
        'What is the guidance in the standards for postmarket issue management?',
        'What are the things to keep in mind when creating an SBOM?',
        'How do I establish a coordinated vulnerability disclosure process?',
        'What is the guidance for device security configuration?',
        'What is the SOP guidance for risk controls?',
        'Write a information security policy.',
      ],
      4
    )
  );

  useEffect(() => {
    if (doc_types?.length && documents_filter === null) {
      setDocumentsFilter(doc_types.find((doc_type) => doc_type.level === 0));
    }
  }, [doc_types, documents_filter, setDocumentsFilter]);

  useEffect(() => {
    if (chat?.entity_id && !chat_history) {
      getChatItems(configuration.api, chat.entity_id)
        .then((data) => {
          setChatHistory(data);
        })
        .catch((error) => console.log(error));
    }
  }, [chat?.entity_id, chat_history, configuration.api]);

  const addToChatHistory = useCallback(
    async (chat_item) => {
      countTokens(configuration.api, chat_item.content).then(
        (content_tokens) => {
          addChatItem(configuration.api, chat?.entity_id, {
            ...chat_item,
            content_tokens,
          }).then((resp) => {
            if (chat_item.role === 'assistant') {
              setGeneration(null);
            }
            setChatHistory((prevHistory) => {
              return [...prevHistory, resp];
            });
          });
        }
      );
    },
    [chat?.entity_id, setChatHistory, configuration.api]
  );

  useEffect(() => {
    if (image_id) {
      setIncludeHistory(false);
    } else {
      setIncludeHistory(true);
    }
  }, [image_id, setIncludeHistory]);

  const generateResponse = useCallback(
    async (prompt, chat_history) => {
      setGeneration('');

      const model = image_id ? MODEL_TYPES.VISION : MODEL_TYPES.TEXT;

      let docs = [];

      if (model === MODEL_TYPES.TEXT) {
        const doc_query = include_history
          ? prompt +
            '\n' +
            [...(chat_history || [])]
              .reverse()
              .map((chat_line) => chat_line.content)
              .join('\n')
          : prompt;
        docs = await searchDocs(
          configuration.api,
          doc_query,
          configuration.generation.num_docs,
          [documents_filter.code]
        );
      }
      const messages = await buildMessages(
        configuration.api,
        configuration.model.llm,
        prompt,
        docs,
        include_history ? chat_history : undefined,
        model === MODEL_TYPES.VISION ? image_data.base64contents : undefined
      );

      const input_tokens = messages.reduce(
        (accumulator, message) => accumulator + message.content_tokens,
        0
      );
      console.log(docs);
      console.log(messages);

      const response = await generateCompletion(
        configuration.api,
        chat?.entity_id,
        model,
        messages,
        configuration?.generation?.max_new_tokens,
        configuration?.generation?.temperature,
        configuration?.generation?.top_p,
        configuration?.generation?.typical_p
      );

      const reader = response.body.getReader();
      let line = '',
        finish_reason,
        answer = '';

      try {
        while (true) {
          const { done, value } = await reader.read();
          line += new TextDecoder().decode(value);
          if (line.endsWith('\n')) {
            const lines = line
              .trim()
              .split('\n')
              .filter((line) => line);
            for (let i = 0; i < lines.length; i++) {
              const data = lines[i].substring(5).trim();
              if (data === '[DONE]') {
                continue;
              } else {
                const result = JSON.parse(data);
                if (result?.choices) {
                  if (result?.choices[0]?.finish_reason) {
                    finish_reason = result?.choices[0]?.finish_reason;
                  }
                  if (
                    !configuration.model.llm.eos_token.includes(
                      result?.choices[0]?.delta?.content
                    )
                  ) {
                    answer += result?.choices[0]?.delta?.content;

                    setGeneration((prevGeneration) => {
                      return (
                        prevGeneration + result?.choices[0]?.delta?.content
                      );
                    });
                  }
                } else {
                  if (!result.generated_text) {
                    answer += result.token.text;
                    setGeneration((prevGeneration) => {
                      return prevGeneration + result.token.text;
                    });
                  }
                }
              }
            }
            line = '';
          }
          if (done) {
            setSourceDocs(docs);
            return {
              generated_text: answer,
              finish_reason,
              input_tokens,
              sources: docs,
            };
          }
        }
      } catch (error) {
        setSourceDocs(docs);
        throw error;
      }
    },
    [
      chat?.entity_id,
      configuration?.generation?.max_new_tokens,
      configuration?.generation?.num_docs,
      configuration?.generation?.temperature,
      configuration?.generation?.top_p,
      configuration?.generation?.typical_p,
      configuration?.model,
      configuration.api,
      documents_filter?.code,
      include_history,
      image_id,
      image_data,
    ]
  );

  useEffect(() => {
    if (chat?.entity_id && !chat_history) {
      getChatItems(configuration.api, chat.entity_id)
        .then((data) => {
          setChatHistory(data);
        })
        .catch((error) => console.log(error));
    }
  }, [chat?.entity_id, chat_history, configuration.api]);

  const handlePromptSubmit = useCallback(
    (prompt) => {
      addToChatHistory({
        name: 'User',
        role: 'user',
        content: prompt,
        order: chat_history.length,
        doc_search_type: documents_filter?.code,
        image_id,
      })
        .then(() => {
          console.log(chat_history);
          setSourceDocs([]);
          generateResponse(prompt, chat_history, image_id)
            .then(async (response) => {
              const generated_tokens = await countTokens(
                configuration.api,
                response.generated_text.trim()
              );
              try {
                await addToChatHistory({
                  name: 'AccQsure',
                  role: 'assistant',
                  content: response.generated_text.trim(),
                  order: chat_history.length + 1,
                  sources: JSON.stringify(response.sources),
                  finish_reason: response.finish_reason,
                  input_tokens: response.input_tokens,
                  generated_tokens,
                  temperature: configuration?.generation?.temperature,
                });
                if (image_id) {
                  setImageData(null);
                  setImageId(null);
                }
              } catch (error) {
                console.log(error);
                setGeneration(null);
              }
            })
            .catch((error) => {
              console.log(error);
              setGeneration(null);
            });
          setPrompt('');
          setPromptError(false);
        })
        .catch((error) => {
          console.log(error);
          setGeneration(null);
        });
    },
    [
      addToChatHistory,
      chat_history,
      configuration?.generation?.temperature,
      generateResponse,
      documents_filter?.code,
      image_id,
      configuration.api,
    ]
  );

  const handleUpdateChatLine = useCallback(
    async (chat_item_id, values) => {
      const resp = await updateChatItem(
        configuration.api,
        chat?.entity_id,
        chat_item_id,
        values
      );
      setChatHistory((prevHistory) => {
        const index = prevHistory.findIndex(
          (line) => line.entity_id === resp.entity_id
        );
        const new_chat_history = [...prevHistory];
        new_chat_history[index] = resp;
        return new_chat_history;
      });
    },
    [chat?.entity_id, configuration.api]
  );

  if (!chat_history) {
    return <LoadingMask />;
  }

  return (
    <>
      <Grid container spacing={1} style={{ padding: '15px' }}>
        <Grid size={12}>
          <Stack direction='row' spacing={1} alignItems={'center'}>
            <Typography variant='h5'>{chat.name}</Typography>
            <IconButton onClick={() => setEditOpen(true)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setDeleteOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid size={12}>
          <ChatBox
            chat_history={chat_history || []}
            generation={generation}
            updateChatLine={handleUpdateChatLine}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant='caption' sx={{ margin: '10px' }}>
            <b>Disclaimer:</b> The generated output is for assistance only and
            should be reviewed by subject matter experts before being used in
            any product or decision.
          </Typography>
        </Grid>
        {source_docs.length ? (
          <Box
            sx={{
              margin: '10px',
            }}
          >
            <Grid size={12}>
              <Divider />
            </Grid>{' '}
            <Grid size={12}>
              <Typography variant='h6'>Sources</Typography>{' '}
            </Grid>
            <Grid container spacing={0} size={12}>
              {source_docs.map((doc, i) => {
                return (
                  <Grid key={`source_doc_${i}`} size={12}>
                    <SourceDocument doc={doc} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : undefined}
        {chat_history.length === 0 && documents_filter?.level !== 3 ? (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <Suggestions
                suggestions={suggestions}
                onClickSuggestion={(prompt) => {
                  handlePromptSubmit(prompt);
                }}
              />
            </Grid>
          </>
        ) : undefined}
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12} paddingBottom={'15px'}>
          <MultiModalPrompt
            chat_id={chat.entity_id}
            image_id={image_id}
            setImageId={setImageId}
            setImageData={setImageData}
            prompt={prompt}
            error={prompt_error}
            setPrompt={setPrompt}
            setError={setPromptError}
            onSubmit={() => {
              if (prompt && !prompt_error) {
                handlePromptSubmit(prompt);
              }
            }}
            disabled={!documents_filter || generation !== null}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 6 }} align='left'>
          <DocumentTypesField
            value={documents_filter}
            setValue={setDocumentsFilter}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 6 }} align='right'>
          <IncludeHistoryField
            value={include_history}
            setValue={setIncludeHistory}
            disabled={Boolean(image_id)}
          />
        </Grid>

        {/* <Grid size={12}>
          <GenerationParameters />
        </Grid> */}
      </Grid>
      <ChatEditDialog
        key={`edit_${chat.updated_at}`}
        chat={chat}
        open={editOpen}
        updateChat={updateChat}
        handleClose={() => setEditOpen(false)}
      />
      <ChatDeleteDialog
        key={`delete_${chat.updated_at}`}
        chat={chat}
        open={deleteOpen}
        deleteChat={deleteChat}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default Chat;
