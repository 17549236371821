import { Container, Typography, Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Outlet } from 'react-router-dom';
import { useUser } from '../providers/UserContext';
import LoadingMask from './shared/LoadingMask';
import { useAuth } from '../providers/AuthContext';
import APP_ROLES from './AppRoles';

const ProtectedRoute = ({ admin, children }) => {
  const user = useUser();
  const auth = useAuth();

  if (auth.user === undefined) {
    return <LoadingMask />;
  }

  if (auth.user === null) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid size={12} justifyContent='center' display='flex'>
              <Typography variant='h5'>
                You must login to access this page.
              </Typography>
            </Grid>
            <Grid size={12} display='flex' justifyContent='center'>
              <Button
                onClick={() => auth.user_manager.signinRedirect()}
                variant='contained'
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (auth.user && !(auth?.roles || []).includes(APP_ROLES.USER)) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid size={12} justifyContent='center' display='flex'>
              <Typography variant='h5'>
                Only authorized users can access this page. Contact your
                AccQsure administrator.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (auth.user && !user.entity_id) {
    return <LoadingMask />;
  }

  // if (user.entity_id && !user.terms_version) {
  //   return <Navigate to='/account/new' />;
  // }

  if (admin && !(auth?.roles || []).includes(APP_ROLES.ADMIN)) {
    return (
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid size={12} justifyContent='center' display='flex'>
            <Typography variant='h5'>
              Only administrators can access this page
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children ? children : <Outlet />;
};
export default ProtectedRoute;
