import React, { useEffect } from 'react';
import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
} from '@mui/material';

import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AppSidebarLayout from '../shared/AppSidebarLayout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthContext';
import APP_ROLES from '../AppRoles';

function Quartermaster() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const app = location.pathname.split('/').at(-1);
  useEffect(() => {
    if (app === 'quartermaster') {
      navigate('/quartermaster/evaluator');
    }
  }, [app, navigate]);

  const NavMenuContent = (
    <>
      <List component='nav'>
        <ListItemButton
          selected={app === 'evaluator'}
          onClick={() => {
            navigate('/quartermaster/evaluator');
          }}
        >
          <ListItemIcon>
            <GradingOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Document Evaluator' />
        </ListItemButton>
        <ListItemButton
          selected={app === 'internalAudit'}
          onClick={() => {
            navigate('/quartermaster/internalAudit');
          }}
        >
          <ListItemIcon>
            <TroubleshootOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Internal Audits' />
        </ListItemButton>

        <ListItemButton
          selected={app === 'externalAudit'}
          onClick={() => {
            navigate('/quartermaster/externalAudit');
          }}
        >
          <ListItemIcon>
            <PersonSearchOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='External Audits' />{' '}
        </ListItemButton>
      </List>

      {(auth?.roles || []).includes(APP_ROLES.ADMIN) ? (
        <>
          <Box sx={{ flexGrow: 1 }} />
          <Divider sx={{ my: 1 }} />
          <List component='nav'>
            <ListItemButton
              onClick={() => {
                navigate('/admin/quartermaster');
              }}
            >
              <ListItemIcon>
                <EngineeringOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='Configuration' />
            </ListItemButton>
          </List>
        </>
      ) : undefined}
    </>
  );

  return (
    <AppSidebarLayout
      headerTitle={'Quartermaster'}
      HeaderIcon={FactCheckOutlinedIcon}
      SidebarContent={NavMenuContent}
    >
      <Outlet />
    </AppSidebarLayout>
  );
}

export default Quartermaster;
