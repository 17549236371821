export const getManifests = async (
  api,
  document_type_id,
  limit = 50,
  offset = 0,
  name = undefined
) => {
  const resp = await api.get('/manifest', {
    params: { document_type_id, limit, offset, name },
  });
  return resp.data;
};

export const getManifest = async (api, manifest_id) => {
  const resp = await api.get(`/manifest/${manifest_id}`);
  return resp.data;
};

export const createManifest = async (api, values) => {
  const resp = await api.post(`/manifest/`, values);
  return resp.data;
};

export const updateManifest = async (api, manifest_id, values) => {
  const resp = await api.put(`/manifest/${manifest_id}`, values);
  return resp.data;
};

export const deleteManifest = async (api, manifest_id) => {
  const resp = await api.delete(`/manifest/${manifest_id}`);
  return resp.data;
};

export const splitManifest = async (api, manifest_id, values) => {
  const resp = await api.post(`/manifest/${manifest_id}/split`, values);
  return resp.data;
};
