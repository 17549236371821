import React, { useEffect, useState } from 'react';
import {
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingMask from '@/components/shared/LoadingMask';
import { getManifest, splitManifest } from '@/actions/manifest';
import { useConfiguration } from '@/providers/ConfigurationContext';
import ManifestNameField from '../AdminManifestForm/ManifestNameField';
import { useUser } from '@/providers/UserContext';
import { useDocTypes } from '@/providers/DocTypesContext';
import { getManifestChecks } from '@/actions/manifestCheck';
import ManifestCheckTransferList from './ManifestCheckTransferList';

export default function AdminSplitManifestForm() {
  const { manifest_id } = useParams();
  const configuration = useConfiguration();
  const user = useUser();
  const doc_types = useDocTypes();
  const navigate = useNavigate();

  const [revision, setRevision] = useState(0);
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading_manifest, setLoadingManifest] = useState(true);
  const [loading_checks, setLoadingChecks] = useState(true);
  const [manifest, setManifest] = useState(null);
  const [name, setName] = useState(manifest?.name || '');
  const [name_error, setNameError] = useState(false);
  const [manifest_checks, setManifestChecks] = useState(null);
  const [split_checks, setSplitChecks] = useState(null);

  useEffect(() => {
    if (user?.entity_id && !manifest) {
      getManifest(configuration?.api, manifest_id)
        .then((data) => {
          setManifest(data);
          setLoadingManifest(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingManifest(false);
        });
    }
  }, [
    configuration?.api,
    user?.entity_id,
    manifest,
    manifest_id,
    setManifest,
    setLoadingManifest,
  ]);

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [manifest, doc_types]);

  useEffect(() => {
    if (user?.entity_id && manifest?.entity_id && !manifest_checks) {
      getManifestChecks(configuration.api, manifest?.entity_id)
        .then((data) => {
          data.sort((a, b) => {
            if (a.section === b.section) {
              return (a.name > b.name) - (a.name < b.name);
            } else {
              return (a.section > b.section) - (a.section < b.section);
            }
          });
          setManifestChecks(data);
          setLoadingChecks(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingChecks(false);
        });
    }
  }, [
    user?.entity_id,
    manifest_checks,
    setManifestChecks,
    configuration.api,
    manifest?.entity_id,
    setLoadingChecks,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        name,
        checks: split_checks.map((check) => check.entity_id),
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    console.log(payload);
    try {
      await splitManifest(configuration.api, manifest_id, payload);

      const doc_type = doc_types.find(
        (doc_type) => doc_type.entity_id === manifest.document_type_id
      );
      navigate({
        pathname: '/admin/manifests',
        search: `?type=${doc_type.code}`,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading_manifest || loading_checks) {
    return <LoadingMask />;
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
          <Toolbar
            sx={{
              minHeight: '55px !important',
            }}
          >
            <Stack spacing={2} direction='row' alignItems='center'>
              <IconButton onClick={() => navigate(-1)} disabled={loading}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>{`Split ${manifest?.name}`}</Typography>
            </Stack>
          </Toolbar>
        </Paper>
      </Grid>

      <Grid size={12}>
        <Box
          padding='15px'
          sx={{
            maxWidth: 600,
          }}
        >
          <Grid container spacing={2}>
            <Grid size={12}>
              <ManifestNameField
                key={`name_${revision}`}
                value={name}
                error={name_error}
                setValue={setName}
                setError={setNameError}
                setChanged={() => setPristine(false)}
                label={'New Manifest Name'}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid size={12}>
        <ManifestCheckTransferList
          key={`checks_${revision}`}
          source_checks={manifest_checks || []}
          setSplitChecks={setSplitChecks}
        />
      </Grid>

      <Grid size={12}>
        <Divider />
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <LoadingButton
              variant='contained'
              onClick={handleSubmit}
              loading={loading}
              disabled={
                pristine ||
                Boolean(name_error) ||
                !Boolean(name) ||
                !split_checks.length
              }
            >
              Submit
            </LoadingButton>

            <Button
              variant='outlined'
              onClick={() => navigate(-1)}
              disabled={loading}
            >
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </Grid>
    </Grid>
  );
}
