import React from 'react';

import { Typography, Chip } from '@mui/material';
import Grid from '@mui/material/Grid2';

function Suggestions({ suggestions, onClickSuggestion }) {
  return (
    <Grid container spacing={1} style={{ padding: '15px' }}>
      <Grid size={12}>
        <Typography variant='overline'>Examples</Typography>
      </Grid>
      {suggestions.map((suggestion) => {
        return (
          <Grid size={{ xs: 12, md: 6, lg: 6 }} key={suggestion}>
            <Chip
              onClick={() => onClickSuggestion(suggestion)}
              label={suggestion}
              variant='outlined'
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
export default Suggestions;
