import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import round from '@/util/round';

const SCORE_CATEGORIES = {
  GOLDEN: 'golden',
  GROWTH: 'growth',
  CRITICAL: 'critical',
};

const SCORE_CATEGORY_DETAILS = {
  [SCORE_CATEGORIES.GOLDEN]: {
    value: SCORE_CATEGORIES.GOLDEN,
    threshold: 80,
    label: 'Golden Standard',
    description: 'Exceptional compliance with no major issues.',
    color: 'green',
  },
  [SCORE_CATEGORIES.GROWTH]: {
    value: SCORE_CATEGORIES.GROWTH,
    threshold: 50,
    label: 'Room for Growth',
    description:
      'Document is generally compliant but needs minor improvements.',
    color: 'yellow',
  },
  [SCORE_CATEGORIES.CRITICAL]: {
    value: SCORE_CATEGORIES.CRITICAL,
    threshold: 0,
    label: 'Critical Overhaul Needed',
    description: 'Significant gaps or issues requiring immediate attention.',
    color: 'red',
  },
};

const getScoreCategoryDetails = (value) => {
  if (value < SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GROWTH].threshold)
    return SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.CRITICAL];
  if (value < SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GOLDEN].threshold)
    return SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GROWTH];
  return SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GOLDEN];
};

const getValue = (
  critical_check_count,
  regular_check_count,
  critical_check_passed,
  regular_check_passed
) => {
  let value;
  if (critical_check_count === 0) {
    value = (regular_check_passed / regular_check_count) * 100;
  } else if (critical_check_count !== critical_check_passed) {
    value = (critical_check_passed / (critical_check_count * 2)) * 100; // missing critical checks can not go above 50%
  } else {
    value = 50 + (regular_check_passed / (regular_check_count * 2)) * 100;
  }

  return round(value, 0);
};

const getCaption = (
  score_category,
  critical_check_count,
  regular_check_count,
  critical_check_passed,
  regular_check_passed
) => {
  switch (score_category.value) {
    case SCORE_CATEGORIES.CRITICAL:
      if (critical_check_count > 0) {
        return `Failed ${
          critical_check_count - critical_check_passed
        } of ${critical_check_count} critical requirements`;
      } else {
        return `Failed ${
          regular_check_count - regular_check_passed
        } of ${regular_check_count} requirements`;
      }
    case SCORE_CATEGORIES.GROWTH:
      return `Passed all critical requirements but failed ${
        regular_check_count - regular_check_passed
      } of ${regular_check_count} non-critical requirements`;
    case SCORE_CATEGORIES.GOLDEN:
      return `Passed all critical requirements and ${regular_check_passed} of ${regular_check_count} non-critical requirements`;
    default:
      return ``;
  }
};

function InspectionResultGauge({
  critical_non_compliant,
  non_critical_non_compliant,
  critical_compliant,
  non_critical_compliant,
}) {
  const critical_check_count = critical_non_compliant + critical_compliant;
  const regular_check_count =
    non_critical_non_compliant + non_critical_compliant;

  const value = getValue(
    critical_check_count,
    regular_check_count,
    critical_compliant,
    non_critical_compliant
  );
  const score_category = getScoreCategoryDetails(value);

  const caption = getCaption(
    score_category,
    critical_check_count,
    regular_check_count,
    critical_compliant,
    non_critical_compliant
  );

  return (
    <Box padding={'10px'}>
      <Typography variant='subtitle'>Document Health Status</Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <Gauge
            width={250}
            height={250}
            value={value}
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: 'translate(0px, 0px)',
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: score_category.color,
              },
            }}
            text={({ value }) => `${value}`}
          />{' '}
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 6 }} alignContent={'center'}>
          <Typography variant='subtitle'>
            Rating: <b>{score_category.label}</b>
          </Typography>
          <br />
          <Typography variant='caption'>
            {score_category.description}
          </Typography>
          <br />
          <br />
          <Typography variant='body2'>{caption}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
export default InspectionResultGauge;
