import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Box,
} from '@mui/material';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { useUser } from '../../providers/UserContext';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { useAuth } from '../../providers/AuthContext';
import APP_ROLES from '../AppRoles';

function SidebarContent({ toggleDrawer }) {
  const navigate = useNavigate();
  const user = useUser();
  const auth = useAuth();

  if (!user.entity_id) {
    return <></>;
  }

  return (
    <>
      <List component='nav'>
        <ListItemButton
          // sx={{ pl: 4 }}
          onClick={() => {
            navigate('/voyager');
            toggleDrawer();
          }}
        >
          <ListItemIcon>
            <SailingOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Voyager' />
        </ListItemButton>
        <ListItemButton
          // sx={{ pl: 4 }}
          onClick={() => {
            navigate('/quartermaster');
            toggleDrawer();
          }}
        >
          <ListItemIcon>
            <FactCheckOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Quartermaster' />
        </ListItemButton>
        <Tooltip title='Coming Soon' placement='right'>
          <span>
            <ListItemButton
              // sx={{ pl: 4 }}
              onClick={() => {
                navigate('/cartographer');
                toggleDrawer();
              }}
              disabled
            >
              <ListItemIcon>
                <FeedOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='Cartographer' />{' '}
            </ListItemButton>
          </span>
        </Tooltip>
      </List>

      <Divider sx={{ my: 1 }} />
      <List component='nav'>
        <ListItemButton
          onClick={() => {
            navigate('/account');
            toggleDrawer();
          }}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Settings' />
        </ListItemButton>
      </List>
      {(auth?.roles || []).includes(APP_ROLES.ADMIN) ? (
        <>
          <Box sx={{ flexGrow: 1 }} />
          <Divider sx={{ my: 1 }} />
          <List component='nav'>
            <ListItemButton
              onClick={() => {
                navigate('/admin');
                toggleDrawer();
              }}
            >
              <ListItemIcon>
                <EngineeringOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='System Administration' />
            </ListItemButton>
          </List>
        </>
      ) : undefined}
    </>
  );
}

export default SidebarContent;
